import React from "react";
import "./history.css";
import img1 from "../../images/HistoryPage/1.jpeg";
import img2 from "../../images/HistoryPage/2.jpeg";
import img3 from "../../images/HistoryPage/3.jpeg";
import img41 from "../../images/HistoryPage/4_1.jpeg";
import img42 from "../../images/HistoryPage/4_2.jpeg";
import img5 from "../../images/HistoryPage/5.jpeg";

const History = () => {
    return (
        <div className="history">
            <div class="container">
                <h1>எமது வரலாறு</h1>
                <p className="history-para">
                    மூவாயிரத்திற்கும் அதிகமான ஆண்டு வரலாற்றை உடைய எம் கண்ணியாம்
                    தமிழை பல நல்ல அறிஞர்கள் வளர்த்து செழுமைபடுத்தியமை வரலாற்றில்
                    பதிவாகியுள்ளது. தொன்மையான வரலாற்றுப் பாரம்பரியத்தை உடைய
                    தமிழ் இன்றைய நவீன நாகரிகத்திலும் தடம் பதித்து இருப்பதற்கு
                    காரணம் பல நல்லறிஞர்கள் தமிழ் வளர களமாய் பல சங்கங்களையும்
                    மன்றங்களையும் அமைத்து தமிழ் வளர்த்தமையே ஆகும். அந்த வகையில்
                    இலங்கையில் மொறட்டுவை பல்கலைக்கழகம் 1968 ஆண்டு எம் தமிழின்
                    தமிழரின் கலை. கலாசார. இலக்கிய, பண்பாடுகளை பேணி காத்து எம்
                    எதிர்கால சந்ததிக்கு கொண்டு சேர்க்க “தமிழ் மன்றம்” என்ற
                    பெயரில் உருவாக்கப்பட்டது. தமிழின், தமிழ்மக்களின் கலை,
                    கலாச்சார, பண்பாட்டை, விளக்கும் கவிதை, கட்டுரை. நாடகம்.
                    விவாதம் என்று அத்தனை கலையம்சமும் சேர்ந்த “நுட்பம்” எனும்
                    சஞ்சிகை வெற்றிகரமாக வெளியிடப்பட்டது.
                </p>
                <img src={img1} alt="sample" class="img" />
                <p>
                    1970 ம் ஆண்டு தமிழின் தொன்மையும் இலக்கிய இலக்கண அழகையும்
                    உலகிற்கு கூறும் முகமாக முதல் கலாச்சார விழா "கலை விழா" என்ற
                    பெயரில் கொழும்பு இராமகிருஷ்ண மிசன் மண்டபத்தில்
                    வெகுவிமரிசையாக கொண்டாடப்பட்டது. அத்துடன் இரண்டாவது நுட்பம்
                    சஞ்சிகையும் வெளியீட்டு வைக்கப்பட்டது. இவ்வாறே 1971,1972
                    களிலும் கலை விழாக்களும் நட்பம் சஞ்சிகை வெளியிடும் தொடர்ந்த
                    வண்ணம் இருந்தது. இதுவரை தமிழ் மன்றம் என்ற பெயரில் தமிழுக்கு
                    தன்னிகரில்லா சேவையாற்றி 1973ம் ஆண்டு தன் பெயரை “தமிழ்
                    சங்கம்” என்று மாற்றிக்கொண்டு பயணத்தை தொடர்ந்தது. 1976
                    ஈழத்தமிழர் வரலாறில் புது அத்தியாயம் எழுதப்பட்ட ஆண்டு
                    இனப்பிரிவினை தலை தூக்கத் தொடங்கிய காலமது.1976, 1977, 1978,
                    1979 வரை கலை நிகழ்ச்சி, விவாதம், கருத்தரங்கு. நாடகம் போன்ற
                    நிகழ்வுகள் மேடையேற்றப்பட்டது.
                </p>

                <div class="sub-container">
                    <div class="container_img">
                        <img src={img2} alt="sample" />
                    </div>
                    <div class="container_text">
                        1972-1980 வரையான காலம் ஈழத்து நாடக வரலாற்றில் முக்கிய
                        காலம். இந்த காலப்பகுதியில் 19 நாடகங்கள்
                        மேடையேற்றப்பட்டது. தமிழ்ச் சங்கம் அடைந்த பெரும் மைல்கல்.
                        1980 களின் ஆரம்பம் அதிகம் அமைதியிலேயே கழிந்து போனது.
                        தமிழ்சங்கமும் தமிழுக்காய் தன் சேவையை செய்தவண்ணம்
                        இருந்தது. 80 களின் இடைப்பட்ட காலம் இனப்பிரிவினை ஆழ
                        வேரூன்றியது. இதனால் தலை நகரில் பல்கலைக்கழகங்கள் பெரிதும்
                        பாதிக்கப்பட்டது . இந்த காரணத்தால் தமிழ் இலக்கிய
                        மன்றத்தின் செயற்பாடுகள் சில ஆண்டுகளுக்கு
                        நிறுத்தப்பட்டது.
                    </div>
                </div>

                <div class="sub-container">
                    <div class="container_text">
                        1990 களின் ஆரம்ப பகுதிகளில் மீண்டும் தமிழ் மாணவர்களின்
                        அக்கறை, தமிழ் மீது கொண்ட பற்று போன்றவற்றால் மீண்டும்
                        தமிழ் சங்கம் தன் பெயரை “இலக்கிய மன்றம்” என்று பெயரை
                        மாற்றி தன் பயணத்தை விரைவுப்படுத்திக் கொண்டது. 1990 களின்
                        பிற்பகுதி தொடக்கம் 2000 இன் ஆரம்பங்கள் வரை ஈழப்போர்
                        உச்சம் பெற்ற காலம். இத்தனை சூழலிலும் தமிழ் இலக்கிய
                        மன்றம் தன் கடமையை தொடராமலில்லை. 2009 ஆண்டு மூன்று தசாப்த
                        ஈழப்போர் முடிவுக்கு கொண்டுவரப்பட்டாலும் தமிழ்
                        மாணாக்கரின் தமிழ்சார் தொண்டுகள் தொடர்ந்து கொண்டே
                        இருக்கிறது.
                    </div>
                    <div class="container_img">
                        <img src={img3} alt="sample" />
                    </div>
                </div>
                <p>
                    இதுவரை இனப்பிரச்சனை. கலவரம். பொருளாதாரச் சிக்கல்.
                    அடக்குமுறை. ஈழப்போர் என அத்தனை வளைவுகளிலும் பயணித்து ஐந்து
                    தசாப்தங்களை கடந்தும் அரை அங்குலம் கூட அசைக்க படாமல் வான் உயர
                    தூணாய் நிமிர்ந்து நிற்கிறது தமிழ் இலக்கிய மன்றம்.
                </p>
                <div class="sub-container">
                    <img src={img41} class="book-image" alt="sample" />
                    <img src={img42} class="book-image" alt="sample" />
                </div>
                <p>
                    தமிழ் இலக்கிய மன்றமானது தமிழ் பல்கலைக்கழக மாணவர்கள்
                    இடத்திலும் அதை தாண்டி பாடசாலை மாணவர்களிடத்தில் தமிழ் மொழி
                    அறிவையும் மாணவர்கள் தமிழ் மீது உள்ள திறமைகளை வெளிக்கொண்டு
                    வருமுகமாகவும் அது மட்டுமில்லாமல் இதர வெளிக்கள் செயற்பாடு
                    துறைகளில் சாதிக்க துடிக்கும் மாணவர்களுக்கு ஒரு வழி அமைத்துக்
                    குடுப்பதும் மாணவர்கள் தங்கள் ஆளுமைத்திறன் அடுத்த கட்ட
                    துறைகளை சார்ந்த நகர்வுகள் குறித்து ஆலோசனை வளங்குவதும்
                    வறுமையில் அடுத்த நேர பொழுதை எண்ணி அஞ்சி வாழும் மக்களுக்கு
                    தன்னால் ஆனா உதவிகளை செய்வதும் அவர்களின் நிலைமைகளை உலகறிய
                    செய்வதும் என்று மொழிவிருத்தி, ஆளுமைத்திறன் வழிகாட்டி,
                    உதவிக்கரம் என பலவற்றை நோக்காக கொண்டு சொற்கணை, ஒளிச்சுவடு,
                    உதவித்தொகை, ஜீவநதி, பொங்கல் விழா, தமிழருவி போன்ற
                    செயற்பாடுகளை முன்னெடுத்து வருகின்றது.
                </p>
                <img src={img5} alt="sample" class="img" />
                
            </div>
        </div>
    );
};

export default History;
