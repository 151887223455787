import React, { useEffect, useState } from "react";
import './footer.css'
function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-text">
                அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை. © 2024 தமிழ் இலக்கிய மன்றம்
            </div>
        </div>
    );
}

export default Footer